import React, { useEffect, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';

import { Breadcrumb, Button, Space, Divider, message, DatePicker, Select, Radio, Checkbox, Upload } from 'antd';

import MLayout from '../Layout/MLayout';

import './output.css'

import 'leancloud-storage';

import * as XLSX from 'xlsx'
import JSZip from 'jszip';
import { saveAs } from 'file-saver'
import axios from 'axios';

const AV = require("leancloud-storage")

const data = {
    data: [],
    startDate: '',
    endDate: '',
    totalCount: 0,
    orders: [],
    printDatas:[],
    page: 0,
    offset: 0,
    channelId:'all',
    batchId: 'all',
    checkAll12: true,
    checkAll1: false,
    filmCount: 12,
}

const OrderTypeOptions = [
    {
      label: '12张版',
      value: '12',
    },
    {
      label: '单张版',
      value: '1',
    },
];

const props = {
    action: 'https://server.onebloc.cn/uploadOrder',
    onChange({ file, fileList }) {
      console.log('上传onChange: ',file.status);
      if (file.status === 'done') {
        alert(`上传成功`)
      } else if (file.status === 'error') {
        alert(`上传失败，请重试`)
      }
    },
    defaultFileList: [],
  };
  


function Output() {

    const [orderType, setOrderType] = useState('12');

    const [skuDatas, setSkuDatas] = useState([{ value: '6517986261612b3201f976e8', label: 'Moment底片 x12', filmCount: 12}])
    const [skuOptions, setSkuOptions] = useState([{ value: '6517986261612b3201f976e8', label: 'Moment底片 x12', filmCount: 12}])
    const [skuSelectedIds, setSkuSelectedIds] = useState(['6517986261612b3201f976e8'])


    const [orderCount, setOrderCount] = useState(0);
    const [loadingText, setLoadingText] = useState("");
    const [channelDatas, setChannelDatas] = useState([{ value: 'all', label: '全部渠道'}])

    const [batchDatas, setBatchDatas] = useState([{ value: 'all', label: '全部活动'}])
    const [batchData, setBatchData] = useState({ value: 'all', label: '全部活动'})

    const PAGE_LIMIT = 1000; 

    useEffect(() => {
        
        console.log('Output useEffect')

        // AV.init({ // 测试
        //     appId: "I5tgGDDyQcTy9vrqCqBosUBw-gzGzoHsz",  
        //     appKey: "zf9DK8ryNyvfnjvQlDc6JFvo", 
        //     serverURL: "https://test-api.onebloc.cn", 
        //     masterKey:"UmnI0pJZJU4HquzXWd0Rgwq6",
        //     useMasterKey: true,
        // })

        AV.init({ // 正式
            appId: "4CNxWZ3qzIZrqDnNRszxIJhY-gzGzoHsz",  
            appKey: "vmVtdLbg08QyTKmnXGUhS2OS", 
            serverURL: "https://api.onebloc.cn",
            masterKey:"owDPUyiK2VmEQd9x1AYvQtnz",
            useMasterKey: true,
        })
        AV.debug.enable()
        loadSkuDatas()
        loadChannelDatas()
    }, [])  


    const loadChannelDatas = () => {
        const query = new AV.Query("M_Channel");
        query.find().then((channels) => {
            console.log(`渠道获取成功：${channels}`);

            var ms = [{ value: 'all', label: '全部渠道'}]
            const channelMs = channels.map((channel, index) => {
                const dict = channel.toJSON()
                const m = {value: dict['objectId'], label: dict['name']}
                return m
            });
            ms = ms.concat(channelMs)
            setChannelDatas(ms)

        });
    }

    const loadBatchesDatas = () => {

        if (data.channelId.length > 0 && data.channelId !== "all") {

            const query = new AV.Query("M_ChannelBatch");
            query.equalTo('channelId', data.channelId)
            query.find().then((batches) => {
                console.log(`活动获取成功：${batches}`);

                const bd = { value: 'all', label: '全部活动'}
                var ms = [bd]
                const batchMs = batches.map((channel, index) => {
                    const dict = channel.toJSON()
                    const m = {value: dict['objectId'], label: dict['name']}
                    return m
                });
                ms = ms.concat(batchMs)
                data.batchId = bd.value
                setBatchData(bd)
                setBatchDatas(ms)
            });
        } else {
            const bd = { value: 'all', label: '全部活动'}
            const ms = [bd]
            data.batchId = bd.value
            setBatchData(bd)
            setBatchDatas(ms)
        }
        
    }

    const loadSkuDatas = () => {

        const query = new AV.Query("PricePackage");
            query.find().then((skus) => {
                console.log(`活动sku成功：${skus}`);
                const skuMs = skus.map((sku, index) => {
                    const dict = sku.toJSON()
                    dict["value"] = dict['objectId']
                    dict["label"] = dict['productName']
                    console.log(`sku转dict：`);
                    console.log(dict)
                    return dict
                });
                if (data.checkAll12) {
                    const filter12Skus = skuMs.filter(skuM => skuM.filmCount === 12);
                    setSkuOptions(filter12Skus)
                    const filter12SkuIds = filter12Skus.map((m, index) => {
                        return m.objectId
                    });
                    setSkuSelectedIds(filter12SkuIds)
                } else {
                    const filter1Skus = skuMs.filter(skuM => skuM.filmCount === 1);
                    setSkuOptions(filter1Skus)
                    const filter1SkuIds = filter1Skus.map((m, index) => {
                        return m.objectId
                    });
                    setSkuSelectedIds(filter1SkuIds)
                }
                setSkuDatas(skuMs)
            });
        
    }

    const [messageApi, contextHolder] = message.useMessage();
    const success = (string) => {
        messageApi.open({
        type: 'success',
        content: string,
        });
    };

    const validateFilter = () => {

        if (data.startDate.length <= 0) {
            console.log(`开始时间 ${data.startDate}`);
            alert('请重新选择开始时间');
            return;
        }
        if (data.endDate.length <= 0) {
            console.log(`结束时间 ${data.endDate}`);
            alert('请重新选择结束时间');
            return;
        }
        if (data.startDate > data.endDate) {
            alert('开始时间不得大于结束时间，请重新选择时间');
            return;
        }
        
        console.log(`开始时间 startDate: ${data.startDate}`);
        console.log(`结束时间 endDate: ${data.endDate}`);
        data.page = 0;
        data.orders = []
        data.data = []
        data.printDatas = []
        fetchOrders();
    };

    const fetchOrders = async () => {

        const startDateDate = new Date(`${data.startDate} 00:00:00`)
        const endDateDate = new Date(`${data.endDate} 23:59:59`)
        const startTimeStamp = startDateDate.getTime() / 1000
        const endTimeStamp = endDateDate.getTime() / 1000
        console.log("start timeStamp ", startTimeStamp)
        console.log("end timeStamp ", endTimeStamp)
        console.log("skuSelectedIds ", skuSelectedIds)
        var query = new AV.Query('Order')

        var defaultSkus = skuSelectedIds.filter(skuid => skuid === '6517986261612b3201f976e8');
        if (data.checkAll12 && defaultSkus.length > 0) {
            // 6517986261612b3201f976e8
            console.log(`筛选已选择12张的sku`)
            console.log(skuSelectedIds)

            const sku1Query = new AV.Query("Order");
            sku1Query.containedIn('skuId', skuSelectedIds)
            
            const sku2Query = new AV.Query("Order");
            sku2Query.doesNotExist('skuId')

            const sku3Query = new AV.Query("Order");
            sku3Query.equalTo('skuId', '')
            
            query = AV.Query.or(sku1Query, sku2Query, sku3Query)
            data.filmCount = 12
            
        } else {
            console.log(`筛选已选择1张的sku`)
            console.log(skuSelectedIds)

            const sku1Query = new AV.Query("Order");
            sku1Query.containedIn('skuId', skuSelectedIds)
            query = sku1Query
            data.filmCount = 1

        } 
        
        query.greaterThanOrEqualTo("purchasingDate", startTimeStamp);
        query.lessThanOrEqualTo("purchasingDate", endTimeStamp);

        query.greaterThanOrEqualTo('status', 2)
        query.ascending('purchasingDate');

        if (data.channelId.length > 0 && data.channelId !== "all") {
            query.equalTo('channelId', data.channelId)
        }

        if (data.batchId.length > 0 && data.batchId !== "all") {
            query.equalTo('batchId', data.batchId)
        }

        query.limit(PAGE_LIMIT)

        data.offset = data.page * PAGE_LIMIT
        
        query.skip(data.offset)

        query.find().then((orderObjects) => {
            
            fetchOrderGroupInfos(orderObjects, 0, 2, [])
            
        });
    };

    const fetchOrderGroupInfos = (orders, index, subGroupLength, results) => {

        const currentIndex = index + subGroupLength <  orders.length ?  index + subGroupLength : orders.length
    
        var datas = orders.slice(index, currentIndex)
    
        const promises = [] 
    
        datas.forEach((item, index) => {
            const promise = fetchOrderExtraInfo(item)
            promises.push(promise)
        });
    
        Promise.all(promises).then(() => {
            
            const orderResults = datas.map((orderObj, orderIdx) => {
                var dict = orderObj.toJSON()
                const order = {
                    'Order#': dict['orderDisplayNumber'],
                    'Express': dict['express'],
                    'ExpressNum': dict['expressNum'],
                    'Folder#': orderIdx+index+1,
                    'Name': dict['customer'],
                    'Phone': dict['phoneNumber'],
                    'City': dict['city'],
                    'Add.': dict['address'],
                    'Address': `${dict['city']}${dict['address']}`,
                    '#ID': dict['userCode'],
                    'Duration': dict['duration'],
                    '#frm.user': dict['orderNumberCount'],
                }
                order['Created'] = fullDateStringFromTimeStamp(dict['purchasingDate'])
                order['#ID Month'] =  monthFromTimeStamp(dict['userRegistDate'])
                order['#ID Year'] =  yearFromTimeStamp(dict['userRegistDate'])
                order['#ID'] =  `${Number(dict['userCode'])}`.padStart(4, '0')
                order['Channel'] = dict['batchName']
                order['UserMessage'] = dict['userMessage']

                return order
            });

            data.orders = data.orders.concat(orderResults)

            var resultSum = results.concat(orderResults)

            if (currentIndex >= orders.length) {
                console.log(`完成全部订单的用户信息`);

                if (data.page <= 0) {
                    data.data = orders
                } else {
                    data.data = data.data.concat(orders)
                }

                data.totalCount = data.data.length;

                if (orders.length >= PAGE_LIMIT) {
                    data.page = data.page + 1
                    fetchOrders()
                } else {
                    success(`查询成功，共有${data.totalCount}个订单`)
                    setOrderCount(data.totalCount)
                }

            } else {
                console.log(`完成第${index}获取渠道的码信息`);
                fetchOrderGroupInfos(orders, currentIndex, subGroupLength, resultSum)
            }

        }).catch(err => {
            alert(`数据查询失败，${err.toString()}`);
        })
    }

    const fetchOrderExtraInfo = (order) => {

        return new Promise((resolve, reject) => {

            console.log(`获取订单${order.get("objectId")}的user:${order.get("userId")}`)

            resolve(order)
            
        })

    }

    const monthFromTimeStamp = (timeStamp) => {
        const date = new Date(timeStamp * 1000); // 根据时间戳创建Date对象
        const month = date.getMonth() + 1; // 获取月份，需要加1
        const string = `${Number(month)}`.padStart(2, '0')
        console.log('转化月时间')
        console.log(timeStamp)
        console.log(date)
        console.log(month)
        console.log(string)
        return string
    }

    const yearFromTimeStamp = (timeStamp) => {
        const date = new Date(timeStamp * 1000); // 根据时间戳创建Date对象
        const year = date.getFullYear(); // 获取年份
        const string = `${Number(year)}`.padStart(4, '0')
        console.log('转化年时间')
        console.log(timeStamp)
        console.log(date)
        console.log(year)
        console.log(string)
        return string
    }

    const convertTimestampToTimezoneString = (timestamp, timezone) => {
        // 创建一个新的 Date 对象
        const date = new Date(timestamp * 1000);
    
        // 使用 toLocaleString 方法根据指定的时区格式化日期
        const options = {
            timeZone: timezone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };
    
        // 获取格式化后的日期字符串
        const localeDateString = date.toLocaleString('en-GB', options);

        // 将格式化后的日期字符串转换为 "yyyy-MM-dd HH:mm:ss" 格式
        const [datePart, timePart] = localeDateString.split(', ');
        const [day, month, year] = datePart.split('/');
        return `${year}-${month}-${day} ${timePart}`;
    }
    

    const fullDateStringFromTimeStamp = (timeStamp) => {
        const date = new Date(timeStamp * 1000); // 根据时间戳创建Date对象
        const year = date.getFullYear(); // 获取年份
        const yearString = `${Number(year)}`.padStart(4, '0')

        const month = date.getMonth() + 1; // 获取月份，需要加1
        const monthString = `${Number(month)}`.padStart(2, '0')

        const day = date.getDate(); // 获取日
        const dayString = `${Number(day)}`.padStart(2, '0')

        const hour = date.getHours(); 
        const hourString = `${Number(hour)}`.padStart(2, '0')

        const min = date.getMinutes(); 
        const minString = `${Number(min)}`.padStart(2, '0')

        const seconds = date.getSeconds(); 
        const secondsString = `${Number(seconds)}`.padStart(2, '0')

        const string = `${yearString}-${monthString}-${dayString} ${hourString}:${minString}:${secondsString}`

        return string
    }


    const currentDateString = () => {
        const date = new Date();
        const year = date.getFullYear(); // 获取年份
        const yearString = `${Number(year)}`.padStart(4, '0')

        const month = date.getMonth() + 1; // 获取月份，需要加1
        const monthString = `${Number(month)}`.padStart(2, '0')

        const day = date.getDate() ; // 获取日
        const dayString = `${Number(day)}`.padStart(2, '0')

        const string = `${yearString}${monthString}${dayString}`

        return string;
    };

    const onlyExportToExcel = () => {
        setLoadingText(`，正在下载中，请勿操作！`)
        const orders = data.orders;
        console.log(`开始导出 data.orders：${data.orders}`);
        console.log(`开始导出：${orders}`);

        /* 创建 workbook 和 worksheet */
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(orders);

        /* 将 worksheet 添加到 workbook */
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        var zip = new JSZip()

        const toDateString = currentDateString()
        var allFolder = zip.folder(`${toDateString}`)

        var excelData = XLSX.write(workbook, { type: "binary", bookType: "xlsx" });

        allFolder.file(`${toDateString}.xlsx`, excelData, { binary: true });

        success(`文件压缩中。。。`)
        setLoadingText(`，文件压缩中。。。`)
        
        // 创建一个数组来保存压缩数据块
        const blobChunks = [];

        const zipStream = zip.generateInternalStream({ type: 'blob' });

        // 设置data事件处理程序
        zipStream.on('data', function (chunk) {
            // 在每次生成数据块时触发
            // data 是一个 Uint8Array
            // metadata 包含有关数据的信息，如当前文件和百分比

            // 在这里处理数据，例如将数据写入文件或上传到服务器
            // 在这个示例中，我们将数据追加到一个数组中以构建完整的ZIP文件
            console.log('压缩某一块');
            
            blobChunks.push(chunk);
        });

        // 设置error事件处理程序
        zipStream.on('error', function (e) {
            console.log('压缩出错');
            // 处理错误
            console.error('Error generating zip:', e);
        });

        // 设置end事件处理程序
        zipStream.on('end', function () {
            // 在生成完成后触发
            setLoadingText(`，文件压缩完成`)
            console.log('压缩完成');
            console.log(blobChunks);
            const blob = new Blob(blobChunks, { type: 'application/zip' });
            console.log('blob');
            console.log(blob);
            // 使用FileSaver.js保存Blob为.zip文件
            saveAs(blob, `${toDateString}.zip`);
            setLoadingText(`，订单已导出`)
        });

        // 启动数据流的生成过程
        zipStream.resume();
    }

    const splitArray = (array, n) => {
        let result = [];
        for (let i = 0; i < array.length; i += n) {
            result.push(array.slice(i, i + n));
        }
        return result;
    };

    const exportToExcel = () => {

        data.printDatas = []

        setLoadingText(`，正在下载中，请勿操作！`)
        const orders = data.orders;
        console.log(`开始导出 data.orders：${data.orders}`);
        console.log(`开始导出：${orders}`);

        /* 创建 workbook 和 worksheet */
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(orders);

        /* 将 worksheet 添加到 workbook */
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        var zip = new JSZip()

        const toDateString = currentDateString()
        const allFolderName = `${toDateString}_${data.filmCount}`
        var allFolder = zip.folder(allFolderName)

        var excelData = XLSX.write(workbook, { type: "binary", bookType: "xlsx" });

        allFolder.file(`${toDateString}.xlsx`, excelData, { binary: true });

        skuDatas.forEach((item, index) => {
            const urlString = item.printAttribute.templateUrl
            console.log(`下载边框模板url：${urlString}`)
            axios({
                method: 'get',
                url: urlString,
                responseType: 'blob'
            })
                .then(res => {
                    console.log(`下载边框模板成功：${item.printAttribute.template}.tif`)
                    allFolder.file(`${item.printAttribute.template}.tif`, res.data, { binary: true }) 
                })
                .catch(err => {
                    console.log(`下载边框模板失败：${err.toString()}`)
                })
        });

        downloadImagesAt(0, allFolder, allFolderName, ()=> {
            success(`文件压缩中。。。`)
            setLoadingText(`，文件压缩中。。。`)

            var factoryDatas = []

            if (data.filmCount > 1) {
                const arrays = splitArray(data.printDatas, 12)
                for (let i = 0; i < arrays.length; i++) {
                    let factoryData = {
                        '打印编号': '',
                    }
                    const array = arrays[i]
                    factoryData['打印编号'] = `${i + 1}`
                    for (let j = 0; j< array.length; j++) {
                        const item = array[j]
                        factoryData[`@pic${j+1}`] = item.pic
                        factoryData[`@border${j+1}`] = item.border
                        factoryData[`ymd${j+1}`] = item.ymd
                        factoryData[`hms${j+1}`] = item.hms
                        if (data.filmCount > 1) {
                            
                        } else {
                            factoryData[`message${j+1}`] = (item.message && item.message.length > 0) ? item.message : 'Moment1'
                        }
                    }
                    factoryDatas.push(factoryData)
                }
            } else {
                for (let i = 0; i < data.printDatas.length; i++) {
                    let factoryData = {
                        '打印编号': '',
                    }
                    const item = data.printDatas[i]
                    factoryData[`@pic1`] = item.pic
                    factoryData[`@border1`] = item.border
                    factoryData[`ymd1`] = item.ymd
                    factoryData[`hms1`] = item.hms
                    factoryData['打印编号'] = `${i + 1}`
                    factoryData[`message1`] = (item.message && item.message.length > 0) ? item.message : 'Moment1'
                    factoryDatas.push(factoryData)
                }
            }

            const factoryExcelWorkbook = XLSX.utils.book_new();
            const factoryExcelWorksheet = XLSX.utils.json_to_sheet(factoryDatas);
            /* 将 worksheet 添加到 workbook */
            XLSX.utils.book_append_sheet(factoryExcelWorkbook, factoryExcelWorksheet, 'Sheet1');
            var factoryExcelData = XLSX.write(factoryExcelWorkbook, { type: "binary", bookType: "xlsx" });
            allFolder.file(`${toDateString}_factory.xlsx`, factoryExcelData, { binary: true });

            // 创建一个数组来保存压缩数据块
            const blobChunks = [];

            const zipStream = zip.generateInternalStream({ type: 'blob' });

            // 设置data事件处理程序
            zipStream.on('data', function (chunk) {
                // 在每次生成数据块时触发
                // data 是一个 Uint8Array
                // metadata 包含有关数据的信息，如当前文件和百分比

                // 在这里处理数据，例如将数据写入文件或上传到服务器
                // 在这个示例中，我们将数据追加到一个数组中以构建完整的ZIP文件
                console.log('压缩某一块');
                
                blobChunks.push(chunk);
            });

            // 设置error事件处理程序
            zipStream.on('error', function (e) {
                console.log('压缩出错');
                // 处理错误
                console.error('Error generating zip:', e);
            });

            // 设置end事件处理程序
            zipStream.on('end', function () {
                // 在生成完成后触发
                setLoadingText(`，文件压缩完成`)
                console.log('压缩完成');
                console.log(blobChunks);
                const blob = new Blob(blobChunks, { type: 'application/zip' });
                console.log('blob');
                console.log(blob);
                // 使用FileSaver.js保存Blob为.zip文件
                saveAs(blob, `${toDateString}.zip`);
                setLoadingText(`，订单已导出`)
            });

            // 启动数据流的生成过程
            zipStream.resume();

            // zip.generateAsync({ type: 'blob' }).then((content) => {
            //     saveAs(content, `${toDateString}.zip`);
            //     success( `压缩完成！`)
            //     setLoadingText( `压缩完成！`)
            // });
        });

    };

    const downloadImagesAt = (orderIndex, ALLFolder, ALLFolderName, callback) => {

        const order = data.data[orderIndex].toJSON()
        const orderId = order["objectId"]
        const orderFilmCount = order["totalCount"]

        var folder = ALLFolder

        setLoadingText(`，当前订单共 ${orderFilmCount} 个订单`)
        setLoadingText(`，正在下载第 ${orderIndex+1} 个订单，请勿操作！`)
        console.log(`开始导出 orderId：${orderId}`);

        const query = new AV.Query("Product");
        query.equalTo('orderId', orderId)
        query.ascending('createDate');
        query.find().then((productObjects) => {

            if (productObjects.length < orderFilmCount) {
                alert(`数据错误，订单${orderId}照片数量为${productObjects.length}`);
            }

            var products = []
            var createDates = []
            if (productObjects.length > orderFilmCount) {
                productObjects.forEach((product, index) => {
                    const createDate = product.get("createDate")
                    if (isNaN(createDate) || createDate <= 0) {
                        products.push(product)
                    } else if (!createDates.includes(createDate)) {
                        products.push(product)
                        createDates.push(createDate)
                    } 
                });
                if (products.length < orderFilmCount) {
                    alert(`数据错误，订单${orderId}的可用照片数量为${products.length}`);
                }
            } else {
                products = productObjects
            }
            const skus = skuDatas.filter(skuItem => skuItem.objectId === order.skuId);
            console.log(`该订单skus`);
            console.log(skus);
            
            const skuFileName = (skus && skus.length > 0) ? `${skus[0].printAttribute.template}.tif` : 'default.tif'
            const userMessage = order.userMessage

            const promises = []
            products.forEach((item, index) => {
                const dict = item.toJSON()
                console.log(`正在下载 order：${dict["imageUrl"]}`);
                // console.log(dict);
                if (index < orderFilmCount) {

                    var dateString = ""
                    if (dict.createDateTimeZone && dict.createDateTimeZone.length > 0) {
                        dateString = convertTimestampToTimezoneString(dict.createDate, dict.createDateTimeZone)
                    } else {
                        dateString = convertTimestampToTimezoneString(dict.createDate, "Asia/Shanghai")
                    }
                    console.log(dict.createDateTimeZone);
                    console.log(dateString);
                    const dateStrings = dateString.split(" ")
                    let ymd1 = dateStrings[0].replace(/-/g, "·")
                    const ymd = ymd1.slice(2)
                    const hms = dateStrings[1].replace(/:/g, "·")
                    
                    const printData = {
                        'pic': `${ALLFolderName}/${orderIndex+1}_${`${index + 1}`.padStart(5, '0')}.jpeg`,
                        'border': `${ALLFolderName}/${skuFileName}`,
                        'ymd': ymd,
                        'hms': hms,
                        'message': userMessage,
                    }
                    data.printDatas.push(printData)

                    const promise = getFile(dict["imageUrl"]).then(data => {
                        const indexString = `${index + 1}`.padStart(5, '0')
                        folder.file(`${orderIndex+1}_${indexString}.jpeg`, data, { binary: true }) // 逐个添加文件
                    })
                    promises.push(promise)
                }
                
            });

            Promise.all(promises)
            .then(() => {
                console.log(`下载${orderFilmCount}张成功`);
                if (orderIndex < data.data.length - 1) {
                    downloadImagesAt(orderIndex + 1, ALLFolder, ALLFolderName, callback)
                } else {
                    callback()
                }
            })
            .catch(res => {
                alert(`订单${orderId}的照片下载错误:${res.toString()}`);
                if (orderIndex < data.data.length - 1) {
                    downloadImagesAt(orderIndex + 1, ALLFolder, ALLFolderName, callback)
                } else {
                    callback()
                }
            })
            
        });
        
    };

    const getFile = (url) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url,
                responseType: 'blob'
            })
                .then(res => {
                    const img = new Image();

                    console.log(`图片下载成功：${res}`);

                    img.onload = function () {

                        console.log(`图片宽 ${img.width} 图片高 ${img.height}`);

                        // if (img.width > img.height) {

                        //     console.log(`图片宽大于高 需要处理`);

                        //     // 创建一个 Canvas 元素来绘制旋转后的图像
                        //     const canvas = document.createElement('canvas');
                        //     const ctx = canvas.getContext('2d');
                        
                        //     // 计算旋转后的尺寸
                        //     const newWidth = img.height;
                        //     const newHeight = img.width;
                        
                        //     // 设置 Canvas 的尺寸
                        //     canvas.width = newWidth;
                        //     canvas.height = newHeight;
                        
                        //     // 旋转图像
                        //     ctx.translate(newWidth / 2, newHeight / 2);
                        //     ctx.rotate((90 * Math.PI) / 180);
                        //     ctx.drawImage(img, -img.width / 2, -img.height / 2);

                        //     console.log(`图片旋转完成`);

                        //     // 步骤 3: 将旋转后的图像保存为 Blob 或 Data URL
                        //     canvas.toBlob(
                        //         function (blob) {
                        //             console.log(`图片导出完成 ${blob}`);
                        //             resolve(blob)
                        //         },
                        //         'image/jpeg', // 可根据需要更改图像格式
                        //         0.9 // 图像质量，可根据需要更改
                        //     );
                        // } else {
                            resolve(res.data)
                        // }
                        
                    };

                    img.src = URL.createObjectURL(res.data);
                    
                })
                .catch(err => {
                    reject(err.toString())
                })
        })
    };

    const  onChangeStartDate = (date, dateString) => {
        console.log(date, dateString);
        data.startDate = dateString
    }

    const  onChangeEndDate = (date, dateString) => {
        console.log(date, dateString);
        data.endDate = dateString
    }

    const  onCheckOrders = () => {
        validateFilter()
    }

    const  onOutputOrders = () => {
        exportToExcel()
    }

    const  onOutputExcel = () => {
        onlyExportToExcel()
    }

    const handleChannelSelectChange = (value) => {
        console.log(`selected ${value}`);
        data.channelId = value
        loadBatchesDatas()
    };

    const handleBatchSelectChange = (value, option) => {
        console.log(`选择了活动 ${value}`);
        console.log(`选择了活动： ${option}`);
        data.batchId = value
        setBatchData(option)
    };

    const onChangeOrderType = ({ target: { value } }) => {
        console.log('选择order 版本：', value);
        if (value === '12') {
            data.checkAll12 = true
            data.checkAll1 = false
        } else {
            data.checkAll12 = false
            data.checkAll1 = true
        }
        if (data.checkAll12) {
            const filter12Skus = skuDatas.filter(skuM => skuM.filmCount === 12);
            setSkuOptions(filter12Skus)
            const filter12SkuIds = filter12Skus.map((m, index) => {
                return m.objectId
            });
            setSkuSelectedIds(filter12SkuIds)
        } else {
            const filter1Skus = skuDatas.filter(skuM => skuM.filmCount === 1);
            setSkuOptions(filter1Skus)
            const filter1SkuIds = filter1Skus.map((m, index) => {
                return m.objectId
            });
            setSkuSelectedIds(filter1SkuIds)
        }
        setOrderType(value);
    };

    const onChangeSkuIds = (skuIdsValues) => {
        console.log('checked = ', skuIdsValues);
        if (skuIdsValues.length > 0) {
            console.log('1');
            setSkuSelectedIds(skuIdsValues)
        } else {
            console.log('0');
            setSkuSelectedIds(skuSelectedIds)
        }
    };

    return (
        <MLayout selectedKey={'Output'} openKey={'1'}>
            {contextHolder}
            <Breadcrumb
                style={{
                    margin: '10px 16px',
                    fontWeight: 600,
                    color: '#ffffff',
                    fontSize: '18px',
                }}
            >
                <Breadcrumb.Item> 订单导出 </Breadcrumb.Item>
            </Breadcrumb>
            <Divider 
                style={{
                    margin: '10px 0',
                }} 
            />
            
            <div 
                className='main'
                style={{

                }}
            >
                <Space style={{ marginBottom: '10px' }}  >
                    <span style={{ fontWeight: 500, color: '#666666', fontSize: '16px'}}>选择时间：</span>
                    <DatePicker placeholder='选择起始日期' onChange={onChangeStartDate} />
                    <span>～</span>
                    <DatePicker placeholder='选择截止日期' onChange={onChangeEndDate} />
                </Space>
                <br></br>
                <Space style={{ marginBottom: '10px' }}  >
                    <span style={{ fontWeight: 500, color: '#666666', fontSize: '16px'}}>选择渠道：</span>
                    <Select
                        defaultValue="all"
                        style={{
                            width: 150,
                        }}
                        filterOption={true}
                        onChange={handleChannelSelectChange}
                        options={ channelDatas }
                    />
                    <Select
                        defaultValue="all"
                        style={{
                            width: 150,
                        }}
                        filterOption={true}
                        onChange={handleBatchSelectChange}
                        options={ batchDatas }
                        value={ batchData }
                    />
                </Space>
                <br></br>
                <Space style={{ marginBottom: '10px' }}  >
                    <span style={{ fontWeight: 500, color: '#666666', fontSize: '16px'}}>类型筛选：</span>
                    <Radio.Group options={OrderTypeOptions} onChange={onChangeOrderType} value={orderType} optionType="button" />
                </Space>
                <br></br>
                <Space style={{ marginBottom: '10px' }}  >
                    <span style={{ fontWeight: 500, color: '#666666', fontSize: '16px'}}>SKU筛选：</span>
                    <Checkbox.Group options={skuOptions} value={skuSelectedIds} onChange={onChangeSkuIds} />
                </Space>
                <br></br>
                <Space style={{ marginBottom: '10px', marginTop:'10px'}}  >
                    <Button onClick={(e) => { onCheckOrders() }}>查询订单</Button>
                </Space>
                <br></br>
                <Space style={{ marginBottom: '10px', marginTop:'10px' }}  >
                <div>
                    <span style={{ fontWeight: 500, color: '#333333', fontSize: '18px'}}>共筛选出 {orderCount} 条已完成订单</span>
                    <span style={{ fontWeight: 500, color: '#333333', fontSize: '18px'}}>{loadingText}</span>
                </div>
                </Space>
                <br></br>
                
                <br></br>
                <Space style={{ marginBottom: '10px' }}  >
                    <Button onClick={(e) => { onOutputOrders() }}>导出订单与表格</Button>
                </Space>
                <br></br>
                <Space style={{ marginTop: '10px', marginBottom: '10px' }}  >
                    <Button onClick={(e) => { onOutputExcel() }}>仅导出表格</Button>
                </Space>

                <br></br>
                <Space style={{ marginTop: '10px', marginBottom: '10px' }}  >
                    <Upload {...props}>
                        <Button icon={<UploadOutlined />}>回传订单Excel(带物流单号)</Button>
                    </Upload>
                </Space>
                
            </div>

        </MLayout>

    )


}

export default Output;